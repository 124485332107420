// --> Color Declaration
$logo-background-clr: #2d3436;
$logo-lettering-clr-1: #54d4ff;
$logo-lettering-clr-2: #042692;
$corporate-yellow-clr: #fba918;
$corporate-blue-clr: #1754ff;
$power-div-clr: #d63031;
$it-div-clr: #6c5ce7;
$music-div-clr: #badc58;
$custom-shop-clr: #6ab04c;
$center-clr: #dfe6e9;
$background-clr: rgb(228, 228, 228);
$nav-drop-gray: #3f3f3f;
$nav-link-glow: #ffe8b6;
$primary-clr: #1754ff;
$primary-border-clr: MediumBlue;
$secondary-clr: Gray;
$secondary-border-clr: lightSlateGray;
$success-clr: ForestGreen;
$success-dim-clr: rgb(125, 185, 125);
$success-border-color: LimeGreen;
$danger-clr: IndianRed;
$danger-dim-clr: rgb(255, 137, 137);
$danger-border-clr: FireBrick;
$warning-clr: GoldenRod;
$warning-border-clr: DarkOrange;
$window-background-clr: #2d3436;
$clr-transparent: rgba(0, 0, 0, 0);
// <-- Color Declaration
// data veriables -->
$uni-radius: 5px;
$bdr-radius: 10px;

// <-- data veriables
