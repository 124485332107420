@import "../Style/Variables.scss";

.home-page {
	.banner-wrapper {
		display: grid;
		justify-items: center;
	}
	.knowledge-section {
		margin: 10px 0;
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
		gap: 10px;
		justify-items: center;
	}

	.text-section {
		text-align: center;
		background-color: white;
		padding: 10px;
		border-radius: 0.5em;
	}
}
