@import "../Style/Variables.scss";

.Auth-page {
	display: flex;
	justify-content: center;

	.Auth-comp {
		background-color: white;
		padding: 10px;
		border: 2px solid $primary-clr;
	}
}

@media (min-width: 600px) {
	.Auth-page {
		justify-content: right;
	}
}

// @media (min-width: 800px) {
// 	.form-wrapper {
// 		display: grid;
// 		grid-template-columns: 1fr 1fr;
// 		gap: 20px;
// 		width: max-content;
// 	}
// }
