@import "../Style/Variables.scss";

.header-main {
	background-color: white;
	margin-bottom: 10px;

	.header-sub {
		display: grid;
		grid-template-rows: max-content max-content max-content;
		gap: 10px;
		justify-items: center;
		margin-bottom: 5px;

		.logo {
			width: 300px;
			padding: 10px;
			background-color: white;
		}

		.search-bar {
			border: 2px solid $logo-lettering-clr-2;
			border-radius: 0 2em 2em 0;
			display: grid;
			grid-template-columns: 120px auto 34.6px;
			height: max-content;
			width: 100%;

			.react-select-container {
				.react-select__control {
					border: none;
				}
			}

			.txt-search {
				border-width: 0px 1px;
				border-style: solid;
				border-color: gray;
				height: 3em;
				width: 100%;
				padding: 0px 10px;
			}

			.btn-wrapper-right {
				border-radius: 0 1.6em 1.6em 0;
				height: max-content;
				width: max-content;
				overflow: hidden;

				.btn__text {
					display: none;
				}
			}
		}

		.user {
			width: max-content;
			text-align: center;
			padding: 0 10px;
			position: relative;
		}
	}

	.header-nav {
		padding-bottom: 10px;
		display: grid;
		grid-template-columns: 1fr;
		justify-items: center;

		.react-select-container {
			width: 100%;
		}

		.nav-links {
			margin: 0.5em;
		}
	}
}

@media (min-width: 600px) {
	.header-main {
		.header-sub {
			.search-bar {
				grid-template-columns: 120px auto 116.85px;

				.btn-wrapper-right {
					.btn__text {
						display: flex;
					}
				}
			}
		}
		.header-nav {
			align-items: right;
			grid-template-columns: repeat(auto-fit, minmax(210px, 1fr));
			grid-template-rows: none;
			column-gap: 30px;
			align-items: center;
		}
	}
}

@media (min-width: 1000px) {
	.header-main {
		.header-sub {
			display: grid;
			grid-template-columns: max-content auto max-content;
			grid-template-rows: none;
			column-gap: 10px;
			align-items: center;

			.search-bar {
				height: max-content;
			}
		}
	}
}
