@import "../../Style/Variables.scss";

.carousel-comp {
	max-width: 800px;

	.carousel {
		max-height: 400px;
		border-radius: 10px;
		border: 5px solid white;
	}
}
