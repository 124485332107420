@import "../../Style/Variables.scss";

.logged_in-nav {
	display: inline-flex;
	align-items: center;
	height: 100%;
}

.user-menu {
	position: absolute;
	right: -30px;
	top: 60px;
	z-index: 1;
}
