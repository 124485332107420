@import "./Variables.scss";

@import "./Variables.scss";
// --> Background color classes
.bgc-primary {
	background-color: $primary-clr;
}

.bgc-secondary {
	background-color: $secondary-clr;
}

.bgc-success {
	background-color: $success-clr;
}

.bgc-success-dim {
	background-color: $success-dim-clr;
}

.bgc-warning {
	background-color: $warning-clr;
}

.bgc-danger {
	background-color: $danger-clr;
}

.bgc-danger-dim {
	background-color: $danger-dim-clr !important;
}

.bgc-window {
	background-color: $window-background-clr;
}
// <-- Background Color classes
// --> Border color classes
.bdr-primary {
	border-color: $primary-border-clr !important;
}

.bdr-success {
	border-color: $success-border-color !important;
}

.bdr-warning {
	border-color: $warning-border-clr !important;
}

.bdr-danger {
	border-color: $danger-border-clr !important;
}
// <-- Border color classes
// --> Text color classes
.txt-primary {
	color: $primary-clr;
}

.txt-secondary {
	color: $secondary-clr;
}

.txt-success {
	color: $success-clr;
}

.txt-warning {
	color: $warning-clr;
}

.txt-danger {
	color: $danger-clr;
}

.txt-black {
	color: black;
}

.txt-white {
	color: white;
}
// <-- Text color classes
// --> List Styling
.list-with-bullets {
	list-style-type: disc;
}
// <-- List Styling
// --> button styling
button:disabled {
	pointer-events: none;
	cursor: none;
	color: $secondary-clr;
	box-shadow: none;
}

.btn.disabled {
	pointer-events: none;
	cursor: none;
	color: $secondary-clr;
	box-shadow: none;
}

.btn::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	height: 0;
	width: 0;
	border-radius: 0 0 50% 50%;
	background-color: white;
	box-shadow: -2px 0px 5px 5px white;
	opacity: 0;
	transition: all 0.4s ease-out;
}

.btn {
	border: none;
	border-radius: 0.6em;
	height: 3em;
	padding: 0;
	margin: 10px 6px;
	transition: 0.2s;
	cursor: pointer;
	display: inline-flex;
	position: relative;
	overflow: hidden;
}

.btn__text {
	display: inline-flex;
	align-items: center;
	padding: 0 1.5em;
	height: 100%;
}
.btn__icon {
	display: inline-flex;
	align-items: center;
	background-color: rgba(0, 0, 0, 0.1);
	padding: 0 0.8em;
	height: 100%;
}

.btn:hover::before {
	opacity: 0.2;
	height: 200%;
	width: 200%;
}

.btn:active::before {
	background-color: rgba(0, 0, 0, 0.3);
}

.btn-long {
	padding: 10px 30px;
}

.btn-small {
	padding: 5px 10px;
}

.btn-no-margin {
	margin: 0px !important;
}

.btn-left-radius {
	border-radius: 20px 0 0 20px;
}

.btn-right-radius {
	border-radius: 0 20px 20px 0;
}

.btn-no-radius {
	border-radius: 0px !important;
}

.btn-low-margin {
	margin: 2px;
}

.burger-button {
	background-color: $logo-background-clr;
	color: white;
	font-size: 1.5em;
	border: none;
	margin: 10px;
	box-shadow: none;
	cursor: pointer;
}

.btn-normal {
	background-color: $corporate-yellow-clr;
	border-color: $logo-lettering-clr-1;
	color: white;
}

.btn-primary {
	background-color: $primary-clr;
	border-color: $primary-border-clr;
	color: white;
}

.btn-secondary {
	background-color: $secondary-clr;
	border-color: $secondary-border-clr;
	color: white;
}

.btn-success {
	background-color: $success-clr;
	border-color: $success-border-color;
	color: white;
}

.btn-danger {
	background-color: $danger-clr;
	border-color: $danger-border-clr;
	color: white;
}

.btn-warning {
	background-color: $warning-clr;
	border-color: $warning-border-clr;
	color: white;
}
// <-- button styling
// <-- Menu Styling
.menu {
	display: grid;
	grid-template-rows: auto;
	// gap: 5px;
	background-color: white;
	border: 1px solid $logo-lettering-clr-2;
	border-radius: 0.5em;

	.menu-title {
		padding: 5px 20px;
	}

	.menu-item {
		padding: 1em 2em;
		text-decoration: none;
	}

	.menu-item:hover {
		background-color: rgba(84, 107, 168, 0.219);
	}
}
// Menu Styling -->
// Links styling -->
a,
.link {
	color: #383838;
	cursor: pointer;
	transition: 0.2s ease-out;
	font-style: italic;
}

.link-underline {
	text-decoration: underline;
}

a:hover,
.link:hover {
	text-decoration: underline;
}
a.disabled,
.link.disabled {
	color: $secondary-clr;
	pointer-events: none;
}

.icon-link {
	padding: 10px;
	display: inline-flex;
	align-items: center;
	font-size: 1.5em;
	cursor: pointer;
}

.icon-link:hover {
	background-color: rgba(0, 0, 0, 0.3);
	border-radius: 0.5em;
	transition: all 0.6s ease;
}

.nav-link {
	color: white;
	border: none;
	background: none;
	font-size: 1em;
	transition: 0.3s ease-out;
}

.nav-link:hover {
	color: gold;
	text-shadow: 0px 0px 15px gold;
	cursor: pointer;
}

.nav-link.disabled {
	color: $secondary-clr;
	pointer-events: none;
}

.lnk-no-style {
	color: white;
}

.lnk-no-style:hover {
	color: white;
	text-shadow: none;
	text-decoration: underline;
}
// <-- Links styling
// --> input styling
.field-label-right {
	margin: 20px 0;
	display: grid;
	grid-template-columns: 0.4fr 1fr;
	gap: 10px;
}

.field-pair-group {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(15em, 1fr));
	column-gap: 20px;
}

.field {
	padding: 10px 0;
}

.label-top {
	margin: 5px 0px;
	display: block;
	font-size: 0.9em;
}

.label {
	padding-right: 10px;
	display: inline-flex;
	align-items: center;
	width: max-content;
}

.label-right {
	padding-left: 10px;
	display: inline;
	text-align: left;
}

.required::after {
	content: " *";
	font-family: sans-serif;
	color: red;
}

.txt:focus {
	border: 2px solid $primary-clr;
}

.txt {
	font-size: 1em;
	border: 1px solid $logo-lettering-clr-2;
	border-radius: 0.5em;
	padding: 0.8em 0.5em;
}

textarea.fixed {
	resize: none;
}

// -->Auth-Code-input Styling
.auth-code {
	width: 2em;
	padding: 0.5em;
	margin: 0 0.25em;
	border: 1px solid $secondary-border-clr;
	border-radius: 0.5em;
	text-align: center;
	font-weight: 800;
}
// <--Auth-Code-input Styling

.txt-larg {
	width: 20em;
}

.txt-medium {
	width: 15em;
}

.txt-small {
	width: 8em;
}

.txt-tiny {
	width: 4rem;
}

.txt-thin {
	font-size: 0.7em;
	padding: 3px;
}

.txt-full {
	width: 100%;
}

.react-select-container {
	background-color: white;

	.react-select__control {
		border: 1px solid $logo-lettering-clr-2;
		border-radius: 0.5em;
		background-color: transparent;
		height: 100%;
	}

	.react-select__menu {
		border: 1px solid $logo-lettering-clr-2;
		color: black;

		// .react-select__menu-list {
		// 	border-radius: $bdr-radius;
		// 	background-color: white;

		// 	.react-select__option:hover {
		// 		background-color: gray;
		// 	}
		// 	.react-select__option:current {
		// 		background-color: white;
		// 	}
		// }
	}
}

.item {
	margin: 5px;
}
// <-- input styling
// --> Message banner styling
.message-success {
	background-color: $success-clr;
	border: 2px solid $success-border-color;
	border-radius: 5px;
	padding: 10px;
	width: 100%;
	display: inline-block;
	margin: 10px 0;
}

.message-danger {
	background-color: $danger-clr;
	border: 2px solid $danger-border-clr;
	border-radius: 5px;
	padding: 10px;
	width: 100%;
	display: inline-block;
	margin: 10px 0;
}
// <-- Message banner styling
// --> common styling
.hr {
	border-bottom: 1px solid black;
	margin: 0 10px;
}

.inactive {
	pointer-events: none;
}

.icon.inactive {
	color: $secondary-clr !important;
}

.clickable {
	cursor: pointer;
}

.hidden {
	display: none;
}

:focus {
	outline: 1px dotted;
}

.row {
	display: flex;
	height: 100%;
}

.col {
	width: 100%;
	display: flex;
	flex-direction: column;
}

.full-width {
	width: 100% !important;
}

.full-height {
	min-height: 100%;
}

.width-max-content {
	width: max-content;
}

.width-min-content {
	width: min-content;
}

.flex {
	display: flex;
}

.flex-column {
	flex-direction: column;
}

.block {
	display: block;
}

.justify-content-left {
	justify-content: start;
}

.justify-content-center {
	justify-content: center;
}

.justify-content-right {
	justify-content: end;
}

.justify-content-space-between {
	justify-content: space-between;
}

.align-center {
	align-items: center;
}

.center {
	text-align: center !important;
}

.right {
	text-align: right !important;
}

.left {
	text-align: left !important;
}

.justify {
	text-align: justify;
}

.margin-auto-sides {
	margin-left: auto;
	margin-right: auto;
}

.no-border {
	border: none !important;
}

.no-padding {
	padding: 0 !important;
}

.no-margin {
	margin: 0 !important;
}

.padding-10 {
	padding: 10px;
}

.padding-10-right {
	padding-right: 10px;
}

.padding-10-left {
	padding-left: 10px;
}

.padding-20-left {
	padding-left: 20px;
}

.padding-20-right {
	padding-right: 20px;
}

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	list-style: none;
	text-decoration: none;
}

html {
	height: 100%;
}

body {
	font-family: "PT Sans", sans-serif;
	background-color: $background-clr;
	font-size: 12px;
}

.body-wrapper {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.screen-layout {
	height: auto;
	margin: 70px 20px 20px 230px;
}
// <-- common styling
// --> container styling
.container {
	margin: 0 10px;
}

.wrapper {
	padding: 10px 20px;
}
// <-- container styling
@media (min-width: 800px) {
	.container-virtical-center {
		height: 99.66vh;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
}

@media (min-width: 1366px) {
	.container {
		margin: 0 auto;
		max-width: 1366px;
	}
}
